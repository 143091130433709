import React, { FC, useCallback } from 'react';
import { useSettings } from '@wix/tpa-settings/react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { Text, TextButton } from 'wix-ui-tpa/cssVars';

import { DataHook } from '../../../../../constants/DataHook';
import settingsParams from '../../../settingsParams';
import { SectionHeader } from '../../common/SectionHeader';
import { Divider } from '../../common/Divider';
import { useMember } from '../../../../../contexts/widget';
import { classes } from './LoginInfo.st.css';
import { useModal } from '../../../../../contexts/modals/hooks';
import { ChangeLoginEmailModal, ChangeLoginPasswordModal } from '../../modals';

export const LoginInfo: FC = () => {
  const settings = useSettings();
  const { t } = useTranslation();
  const { loginEmail } = useMember();
  const { showModal } = useModal();

  const showChangeLoginEmailModal = useCallback(
    () => showModal(ChangeLoginEmailModal),
    [showModal],
  );
  const showChangeLoginPasswordModal = useCallback(
    () => showModal(ChangeLoginPasswordModal),
    [showModal],
  );

  return (
    <div className={classes.root} data-hook={DataHook.LoginInfoSection}>
      <SectionHeader
        title={settings.get(settingsParams.loginInfoSectionTitle)}
        subtitle={settings.get(settingsParams.loginInfoSectionSubtitle)}
      />
      <div className={classes.content}>
        <div>
          <Text className={classes.title} tagName="div">
            {t('app.widget.sections.login-info.email.title')}
          </Text>
          <Text className={classes.title} tagName="div">
            {loginEmail}
          </Text>
          <TextButton
            data-hook={DataHook.LoginInfoSectionChangeEmail}
            onClick={showChangeLoginEmailModal}
            className={classes.button}
          >
            {t('app.widget.sections.login-info.email.action')}
          </TextButton>
        </div>

        <div>
          <Text tagName="div">
            {t('app.widget.sections.login-info.password.title')}
          </Text>
          <Text tagName="div">● ● ● ● ● ●</Text>
          <TextButton
            data-hook={DataHook.LoginInfoSectionChangePassword}
            onClick={showChangeLoginPasswordModal}
            className={classes.button}
          >
            {t('app.widget.sections.login-info.password.action')}
          </TextButton>
        </div>
      </div>

      <Divider />
    </div>
  );
};
